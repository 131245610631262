html {
    font-size: 62.5%; // 1rem is 10px
}

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    font-family: 'montserrat';
}

body {
    overflow-x: hidden;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -webkit-tap-highlight-color: transparent;
}

.app {
    margin-top: 2rem;
    display: grid;
    justify-items: center;
}